import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";
// @ts-expect-error false positive image import failure
import imgContent from "../../assets/image/alert/desktop2.png";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(37%, -50%);
  }
`;

interface ThemeProps {
  theme: DefaultTheme;
}

const ULStyled = styled.ul<ThemeProps>`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;
  /* justify-content: flex-start;

  @media ${device.lg} {
    justify-content: space-between;
  } */

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #021d2d;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      top: 9px;
      margin-right: 10px;
    }
  }
`;

const Content4 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#F7F7FB"
      className="position-relative"
      py={["50px", null, "50px", "80px", "100px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col
            lg="6"
            className="position-static order-lg-2 d-flex justify-content-center"
          >
            <ImgContainer className=" ml-lg-5">
              <img
                src={imgContent}
                alt=""
                className="img-fluid"
                // @ts-expect-error img does not expect css attribute
                css={`
                  box-shadow: ${({ theme }: ThemeProps) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0 ">
            <div>
              <Title>Streamlined administration.</Title>
              <Text mb={4}>
                Having been developed in association with CRPs and Police
                Forces, Alert gives you everything you need to effortlessly
                operate and administer your scheme. From easy user invitations
                to submission approvals and moderation.
                <br />
                <br />
                Alert also comes with an automated data expiration system, you
                define your retention period and alert will handle the deletion
                of your old data.
              </Text>

              <ULStyled>
                <li>Auto data expiration</li>
                <li>Simple settings</li>
                <li>Submission approval</li>
                <li>Mobile app driven</li>
              </ULStyled>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content4;
